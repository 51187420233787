import { z } from "zod";
import {
  dateStringSchema,
  dateComparisonExpSchema,
  orderBySchema,
  uuidComparisonExpSchema,
} from ".";

export const subscriptionsListBoolExpSchema = z.object({
  cancellationDate: dateComparisonExpSchema.nullish(),
  paymentMethodId: uuidComparisonExpSchema.nullish(),
  id: uuidComparisonExpSchema.nullish(),
});

export type SubscriptionsListBoolExpSchema = z.infer<
  typeof subscriptionsListBoolExpSchema
>;

export const subscriptionsListOrderBySchema = z
  .object({
    cancellationDate: orderBySchema,
  })
  .partial();

export type SubscriptionsListOrderBySchema = z.infer<
  typeof subscriptionsListOrderBySchema
>;

export const subscriptionsListAggregateOrderBySchema = z.object({
  count: orderBySchema.nullish(),
});

export type SubscriptionsListAggregateOrderBySchema = z.infer<
  typeof subscriptionsListAggregateOrderBySchema
>;

export const subscriptionsHistoryBoolExpSchema = z.object({
  expiryDate: dateComparisonExpSchema.nullish(),
  orgId: uuidComparisonExpSchema.nullish(),
  cancellationDate: dateComparisonExpSchema.nullish(),
  id: uuidComparisonExpSchema.nullish(),
});

export type SubscriptionsHistoryBoolExpSchema = z.infer<
  typeof subscriptionsHistoryBoolExpSchema
>;

export const subscriptionsHistoryOrderBySchema = z.object({
  expiryDate: orderBySchema.nullish(),
  startDate: orderBySchema.nullish(),
});

export type SubscriptionsHistoryOrderBySchema = z.infer<
  typeof subscriptionsHistoryOrderBySchema
>;

export const createSubscriptionPlanOneArgsSchema = z.object({
  orgId: z.string().uuid(),
  paymentMethodId: z.string().uuid(),
  planId: z.string().uuid(),
  promoId: z.string().uuid().nullish(),
});

export type CreateSubscriptionPlanOneArgsSchema = z.infer<
  typeof createSubscriptionPlanOneArgsSchema
>;

export const subscriptionsHistoryInsertInputSchema = z.object({
  orgId: z.string().uuid(),
  startDate: dateStringSchema,
  expiryDate: dateStringSchema,
  orderId: z.string().uuid(),
});

export type SubscriptionsHistoryInsertInputSchema = z.infer<
  typeof subscriptionsHistoryInsertInputSchema
>;

export const SubscriptionsListSetInputSchema = z.object({
  paymentMethodId: z.string().uuid(),
});

export type SubscriptionsListSetInputSchema = z.infer<
  typeof SubscriptionsListSetInputSchema
>;
